<template>
  <div>
    <!-- <el-alert
      class="mb-3 text-warning font-weight-bold font-size-lg"
      v-if="invoiceType == null"
      :closable="false"
      :title="$t('PAYMENTS.INVOICE_HAS_PAYMENTS_TO_REFUND')"
      type="warning"
      show-icon
    >
    </el-alert> -->
    <h3 v-if="invoiceType == null" class="text-warning">
      <i class="fa fa-exclamation-triangle"></i>
      {{ $t("PAYMENTS.INVOICE_HAS_PAYMENTS_TO_REFUND") }}
    </h3>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body mb-3">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="payments"
          >
            <el-table-column label="Code" min-width="250px" prop="code" />

            <el-table-column
              :label="$t('COMMON.AMOUNT')"
              prop="amount"
              min-width="150"
            >
              <template v-slot="{ row }">
                {{ $formatCurrency(row.amount) }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.STATUS')"
              prop="status"
              min-width="200"
            >
              <template v-slot="{ row }">
                <payment-status-badge :payment="row" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="refund-payloads">
      <!-- <h5 class="text-warning">
        {{ $t("PAYMENTS.REFUND_WILL_BE_GENERATED") }}
      </h5> -->
      <base-input class="mb-2" :label="$t('PAYMENTS.REFUND_PAYMENT_SOURCE')">
        <el-radio-group v-model="paymentSource">
          <el-radio
            v-for="(label, val) in paymentSources"
            :key="val"
            :name="(invoiceType ? invoiceType + '_' : '') + 'payment_source'"
            :label="val"
            >{{ $t("PAYMENTS." + label) }}</el-radio
          >
        </el-radio-group>
      </base-input>
      <base-input
        v-if="paymentSource == 'DPA'"
        :label="$t('PAYMENTS.SELECT_PAYMENT_ACCOUNT')"
      >
        <payment-method-selector
          :paymentMethod="paymentMethodId?.toString()"
          :filterable="true"
          :showAll="false"
          :allowCreate="false"
          :filterLocation="locationId?.toString()"
          @paymentMethodChanged="paymentMethodSelected"
        />
        <input
          type="hidden"
          v-model="selectedPaymentMethodId"
          :id="(invoiceType ? invoiceType + '_' : '') + 'payment_method_id'"
          :name="(invoiceType ? invoiceType + '_' : '') + 'payment_method_id'"
        />
      </base-input>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, RadioGroup, Radio, Alert } from "element-ui";
import PaymentMethodSelector from "@/views/Pages/AdminModule/PaymentMethodManagement/components/PaymentMethodSelector.vue";
import PaymentStatusBadge from "@/components/PaymentStatusBadge.vue";
import { paymentSourcesOptions } from "@/constants/payments";
export default {
  name: "InvoiceCancelationForm",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Table.name]: Table,
    [Alert.name]: Alert,
    [TableColumn.name]: TableColumn,
    PaymentMethodSelector,
    PaymentStatusBadge,
  },

  props: {
    locationId: {
      type: [String, Number],
      default: null,
    },
    payments: {
      type: Array,
      required: true,
    },
    invoiceType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      paymentMethodId: null,
      paymentSource: null,
      note: null,
      selectedPaymentMethodId: null,
      paymentSources: paymentSourcesOptions,
    };
  },

  created() {
    this.paymentMethodId =
      this.payments[0].source == "DPA"
        ? this.payments[0].payment_method_id
        : null;
    this.selectedPaymentMethodId = this.paymentMethodId;
    this.paymentSource = this.payments[0].source;
  },

  methods: {
    paymentMethodSelected(paymentMethodId) {
      this.selectedPaymentMethodId = paymentMethodId;
    },
  },
};
</script>
