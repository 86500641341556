<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <transaction :transaction="transaction" />
        </div>

        <div v-if="transaction.status != 'CANCELED'" class="col-6 text-right">
          <base-button
            type="button"
            size="sm"
            @click="openValidateTransactionModal"
            class="elite-button add"
            v-if="
              transaction.status != STATUS_PAIED &&
              transaction.status != STATUS_VALIDATED
            "
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.VALIDATE") }}
            </span>
          </base-button>
          <el-dropdown v-if="transaction.status != 'CANCELED'">
            <el-button
              class="btn base-button elite-button add btn-button btn-sm"
              type="primary"
            >
              <span class="btn-inner--icon">
                <i class="far fa-times"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TRANSACTIONS.CANCEL_TRANSACTION") }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="cancelTransaction()">{{
                $t("TRANSACTIONS.CANCEL_TRANSACTION")
              }}</el-dropdown-item>
              <el-dropdown-item
                @click.native="openChangeTransactionBuyerModal()"
                >{{
                  $t("TRANSACTIONS.CHANGE_TRANSACTION_BUYER")
                }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <base-button
              type="button"
              size="sm"
              @click="cancelTransaction(transaction)"
              class="elite-button add"
              v-if="transaction.status != 'CANCELED'"
            >

              <span class="btn-inner--icon">
                <i class="far fa-times"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TRANSACTIONS.CANCEL_TRANSACTION") }}
              </span>
          </base-button> -->
          <base-button
            class="elite-button add"
            icon
            size="sm"
            @click="openCreateReclamationModal"
            v-if="transaction.status != 'CANCELED'"
          >
            <!--<span class="btn-inner--icon">
                <i class="far fa-money"></i>
              </span>-->
            <span class="btn-inner--text">
              {{ $t("RECLAMATIONS.ADD_RECLAMATION") }}
            </span>
          </base-button>
          <!--<base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_TRANSACTIONS)"
            @click="deleteTransaction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_TRANSACTIONS)"
            @click="editTransaction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>-->
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.STATUS") }}</dt>
          <dd>
            <transaction-status-badge-component :transaction="transaction" />
            <!-- {{ $t(`TRANSACTIONS.STATUS_${transaction.status}`) }} -->
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(transaction.price) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.ORGANIZATION_SELLER") }}</dt>
          <dd>
            <organization :organization="transaction.seller" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.LOCATION_SELLER") }}</dt>
          <dd>
            <location :location="transaction.sellerLocation" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.ORGANIZATION_BUYER") }}</dt>
          <dd>
            <organization :organization="transaction.buyer" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.LOCATION_BUYER") }}</dt>
          <dd>
            <location :location="transaction.buyerLocation" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.VEHICLE") }}</dt>
          <dd>
            <vehicle :vehicle="transaction.vehicle" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.SELLER_PAYMENT_METHOD") }}</dt>
          <dd>
            {{
              transaction.seller_payment_method
                ? $t(`TRANSACTIONS.${transaction.seller_payment_method}`)
                : "-"
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.SELLER_SHIPPING_METHOD") }}</dt>
          <dd>
            {{
              transaction.seller_shipping_method
                ? transaction.seller_shipping_method
                : "-"
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.SELLER_SHIPPING_ADDRESS") }}</dt>
          <dd>
            {{
              transaction.seller_shipping_address
                ? transaction.seller_shipping_address
                : "-"
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.BUYER_PAYMENT_METHOD") }}</dt>
          <dd>
            {{
              transaction.buyer_payment_method
                ? $t(`TRANSACTIONS.${transaction.buyer_payment_method}`)
                : "-"
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.BUYER_SHIPPING_METHOD") }}</dt>
          <dd>
            {{
              transaction.buyer_shipping_method
                ? transaction.buyer_shipping_method
                : "-"
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.BUYER_SHIPPING_ADDRESS") }}</dt>
          <dd>
            {{
              transaction.buyer_shipping_address
                ? transaction.buyer_shipping_address
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="transaction.created_at">
            {{ $timeZoneDateFormat(transaction.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="transaction.updated_at">
            {{ $timeZoneDateFormat(transaction.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>

    <div
      v-if="showValidateTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showValidateTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.VALIDATE_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeValidateTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <validate-transaction-component
            :transaction="transaction"
            :transactionId="transaction.id"
            @transactionValidated="transactionValidated($event)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showCreateReclamationModal"
      class="resizable-wrapper"
      v-bind:class="[showCreateReclamationModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("RECLAMATIONS.ADD_RECLAMATION") }}
          </h1>
          <button class="close" @click="closeCreateReclamationModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <add-reclamation-component
            :transaction="transaction"
            :transactionId="transaction.id"
            @reclamationCreated="reclamationCreated($event)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="showChangeTransactionBuyerModal"
      class="resizable-wrapper"
      v-bind:class="[showChangeTransactionBuyerModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.CHANGE_BUYER") }}
          </h1>
          <button class="close" @click="closeChangeTransactionBuyerModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <change-transaction-buyer-form
            :transaction="transaction"
            @transactionBuyerChanged="transactionBuyerChanged()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import {
  STATUS_DELIVERED,
  STATUS_CANCELED,
  STATUS_PENDING,
  STATUS_PAIED,
  STATUS_DRAFT,
  STATUS_INVOICED,
  STATUS_VALIDATED,
} from "@/constants/transactions";
import swal from "sweetalert2";
import { Dropdown, DropdownMenu, DropdownItem, Button } from "element-ui";
import Vehicle from "@/components/Vehicle";
import Location from "@/components/Location";
import Transaction from "@/components/Transaction";
import TransactionStatusBadgeComponent from "../components/TransactionStatusBadgeComponent.vue";
import ValidateTransactionComponent from "../components/ValidateTransactionComponent.vue";
import AddReclamationComponent from "../../ReclamationManagement/components/AddReclamationComponent.vue";
import ChangeTransactionBuyerForm from "./ChangeTransactionBuyerForm.vue";
import TransactionCancelationForm from "@/components/TransactionCancelationForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
export default {
  name: "transaction-view-global",

  components: {
    Location,
    Vehicle,
    Transaction,
    ValidateTransactionComponent,
    AddReclamationComponent,
    TransactionStatusBadgeComponent,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ChangeTransactionBuyerForm,
  },

  props: ["transaction"],

  data() {
    return {
      showValidateTransactionModal: false,
      showCreateReclamationModal: false,
      showChangeTransactionBuyerModal: false,
      renderKey: 1,
      STATUS_DELIVERED,
      STATUS_CANCELED,
      STATUS_PENDING,
      STATUS_PAIED,
      STATUS_DRAFT,
      STATUS_INVOICED,
      STATUS_VALIDATED,
    };
  },

  mixins: [requestErrorMixin],

  computed: {},

  created() {},

  methods: {
    closeValidateTransactionModal(refresh) {
      this.showValidateTransactionModal = false;
    },
    closeCreateReclamationModal(refresh) {
      this.showCreateReclamationModal = false;
    },
    openValidateTransactionModal() {
      this.showValidateTransactionModal = true;
    },
    openCreateReclamationModal() {
      this.showCreateReclamationModal = true;
    },
    editTransaction() {
      this.$emit("onEditTransaction", this.transaction);
    },
    deleteTransaction() {
      this.$emit("onDeleteTransaction", this.transaction);
    },
    transactionValidated() {
      this.showValidateTransactionModal = false;
      this.$emit("onTransactionValidated", this.transaction);
    },
    reclamationCreated(refresh) {
      this.showCreateReclamationModal = false;
      this.$emit("onReclamationCreated");
    },

    closeChangeTransactionBuyerModal() {
      this.showChangeTransactionBuyerModal = false;
    },
    openChangeTransactionBuyerModal() {
      this.showChangeTransactionBuyerModal = true;
    },
    transactionBuyerChanged() {
      this.showChangeTransactionBuyerModal = false;
      this.$emit("onTransactionBuyerChanged", this.transaction);
    },

    async cancelTransaction() {
      let transaction = this.transaction;
      if (
        transaction.sellerInvoice.refundable_payments.length > 0 ||
        transaction.buyerInvoice.refundable_payments.length > 0
      ) {
        await this.cancelTransactionWithValidatedPayment(transaction);
      } else {
        this.loading = true;
        const confirmation = await swal.fire({
          text: this.$t("TRANSACTIONS.CANCEL_TRANSACTION_QUESTION"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.YES"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });

        try {
          if (confirmation.value === true) {
            await this.$store.dispatch("transactions/cancel", transaction);
            this.renderKey++;
            this.$notify({
              type: "success",
              message: this.$t("TRANSACTIONS.TRANSACTION_CANCELED"),
            });
            this.$emit("onTransactionCanceled", transaction);
          }
          this.loading = false;
        } catch (error) {
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }
    },

    async cancelTransactionWithValidatedPayment() {
      this.loading = true;
      let transaction = this.transaction;
      const TransactionCancelationFormConstructor = Vue.extend(
        TransactionCancelationForm
      );
      const instance = new TransactionCancelationFormConstructor({
        propsData: {
          sellerInvoice: transaction.sellerInvoice,
          buyerInvoice: transaction.buyerInvoice,
        },
        i18n: this.$i18n,
        store: this.$store,
      });

      const html = instance.$mount().$el;
      const { value: formValues } = await swal.fire({
        title: this.$t("TRANSACTIONS.CANCEL_TRANSACTION_QUESTION"),
        type: "question",
        customClass: {
          popup: "delete-popup with-component",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
        html: html,
        focusConfirm: true,
        preConfirm: () => {
          const has_seller_refundable_payments =
            transaction.sellerInvoice.refundable_payments.length > 0;
          const has_buyer_refundable_payments =
            transaction.buyerInvoice.refundable_payments.length > 0;

          let seller_pmId = null;
          let seller_source = null;
          let buyer_pmId = null;
          let buyer_source = null;

          if (has_seller_refundable_payments) {
            seller_source = document.querySelector(
              'input[name="seller_payment_source"]:checked'
            )?.value;

            if (!seller_source) {
              swal.showValidationMessage(this.$t("PAYMENTS.SELECT_SOURCE"));
              return null;
            }

            if (seller_source === "DPA") {
              seller_pmId = document.getElementById(
                "seller_payment_method_id"
              ).value;
              if (!seller_pmId) {
                swal.showValidationMessage(
                  this.$t("PAYMENTS.SELECT_SOURCE_ACCOUNT")
                );
                return null;
              }
            }
          }

          if (has_buyer_refundable_payments) {
            buyer_source = document.querySelector(
              'input[name="buyer_payment_source"]:checked'
            )?.value;

            if (!buyer_source) {
              swal.showValidationMessage(this.$t("PAYMENTS.SELECT_SOURCE"));
              return null;
            }

            if (buyer_source === "DPA") {
              buyer_pmId = document.getElementById(
                "buyer_payment_method_id"
              ).value;
              if (!buyer_pmId) {
                swal.showValidationMessage(
                  this.$t("PAYMENTS.SELECT_SOURCE_ACCOUNT")
                );
                return null;
              }
            }
          }
          return {
            seller_source,
            seller_pmId,
            buyer_source,
            buyer_pmId,
          };
        },
      });

      try {
        if (formValues) {
          if (formValues.buyer_source) {
            transaction.buyer_refund_source = formValues.buyer_source;
          }
          if (formValues.buyer_pmId) {
            transaction.buyer_refund_payment_method_id = formValues.buyer_pmId;
          }
          if (formValues.seller_source) {
            transaction.seller_refund_source = formValues.seller_source;
          }
          if (formValues.seller_pmId) {
            transaction.seller_refund_payment_method_id =
              formValues.seller_pmId;
          }
          await this.$store.dispatch("transactions/cancel", transaction);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("TRANSACTIONS.TRANSACTION_CANCELED"),
          });
          this.$emit("onTransactionCanceled", transaction);
        }
      } catch (error) {
        console.error(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    transaction(transaction) {},
  },
};
</script>
