<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper">
      <base-input
        :label="`${$t('TRANSACTIONS.BUYER_PAYMENT_METHOD')} (*)`"
        :placeholder="$t('TRANSACTIONS.BUYER_PAYMENT_METHOD')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('TRANSACTIONS.BUYER_PAYMENT_METHOD')"
          v-model="transaction.buyer_payment_method"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in PAYMENT_METHODS"
            :key="key"
            :label="$t(`TRANSACTIONS.${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.buyer_payment_method" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('TRANSACTIONS.BUYER_SHIPPING_METHOD')} (*)`"
        :placeholder="$t('TRANSACTIONS.BUYER_SHIPPING_METHOD')"
        v-model="transaction.buyer_shipping_method"
        @change="onFormChanged()"
      />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('TRANSACTIONS.BUYER_SHIPPING_ADDRESS')} (*)`"
        :placeholder="$t('TRANSACTIONS.BUYER_SHIPPING_ADDRESS')"
        v-model="transaction.buyer_shipping_address"
        @change="onFormChanged()"
      />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ $t("COMMON.VALIDATE") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import "flatpickr/dist/flatpickr.css";
import { TRANSACTIONS_STATUS } from "@/constants/transactions";
import {
  PAYMENT_METHODS
} from "@/constants/transactions";

export default {
  name: "ValidateTransactionForm",
  
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["transactionData", "formErrors", "loading"],

  data() {
    let transactionData = cloneDeep(this.transactionData);
    transactionData = this.$fillUserOrganizationData(transactionData);
    return {
      transaction: transactionData,
      PAYMENT_METHODS,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  mounted() {},

  methods: {
    async handleSubmit() {
      let transactionData = cloneDeep(this.transaction);
      transactionData = this.$fillUserOrganizationData(transactionData);
      this.$emit("transactionSubmitted", transactionData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    transactionData(transactionData) {
      if (transactionData) {
        this.transaction = {
          ...this.transaction,
          ...cloneDeep(transactionData),
        };
      }
    },
  },
};
</script>
