<template>
  <el-form
    class="add-form change-buyer"
    ref="change_buyer_form"
    :model="newTransaction"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="row">
      <div
        :class="
          'refund-payloads mb-2 ' +
          (hasRefundablePayments ? 'col-md-4' : 'col-md-12')
        "
      >
        <div
          class="form-wrapper full"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <!-- Organization -->
          <el-form-item
            prop="organizationId"
            :rules="[
              {
                required: true,
                message: this.$t('TRANSACTIONS.ORGANIZATION_REQUIRED'),
                trigger: 'blur',
              },
            ]"
          >
            <base-input
              :label="`${$t('COMMON.ORGANIZATION')} (*)`"
              :placeholder="$t('COMMON.ORGANIZATION')"
            >
              <organization-selector
                :filterable="true"
                :showAll="false"
                :excludeIds="[transaction.seller.id, transaction]"
                @organizationChanged="
                  (organizationId, organization) => {
                    newTransaction.organizationId = organizationId;
                  }
                "
              />
            </base-input>
          </el-form-item>
        </div>
        <div
          class="form-wrapper full"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        >
          <!-- Location -->
          <el-form-item
            prop="locationId"
            :rules="[
              {
                required: true,
                message: this.$t('TRANSACTIONS.LOCATION_REQUIRED'),
                trigger: 'blur',
              },
            ]"
          >
            <base-input
              :label="`${$t('COMMON.LOCATION')} (*)`"
              :placeholder="$t('COMMON.LOCATION')"
            >
              <location-selector
                :filterable="true"
                :showAll="false"
                :disabled="!newTransaction.organizationId"
                :organization="newTransaction.organizationId"
                @locationChanged="
                  (locationId, location) => {
                    newTransaction.locationId = locationId;
                  }
                "
              />
            </base-input>
          </el-form-item>
        </div>
        <div class="form-wrapper full">
          <el-form-item
            prop="price"
            :rules="[
              {
                required: true,
                message: this.$t('TRANSACTIONS.PRICE_REQUIRED'),
                trigger: 'blur',
              },
            ]"
          >
            <base-input
              class="mb-2"
              :label="$t('COMMON.AMOUNT')"
              v-model="newTransaction.price"
              type="number"
              :min="0"
              :step="1"
              placeholder="0.00"
            >
            </base-input>
          </el-form-item>
        </div>

        <div class="form-wrapper full">
          <el-form-item
            prop="paymentSource"
            :rules="[
              {
                required: true,
                message: this.$t('TRANSACTIONS.PAYMENT_SOURCE_REQUIRED'),
                trigger: 'blur',
              },
            ]"
          >
            <base-input class="mb-2" :label="$t('PAYMENTS.PAYMENT_SOURCE')">
              <el-radio-group v-model="newTransaction.paymentSource">
                <el-radio
                  v-for="(label, val) in paymentSources"
                  :key="val"
                  name="payment_source"
                  :label="val"
                  >{{ $t("PAYMENTS." + label) }}</el-radio
                >
              </el-radio-group>
            </base-input>
          </el-form-item>
        </div>
        <div
          v-if="newTransaction.paymentSource == 'DPA'"
          class="form-wrapper full"
        >
          <el-form-item
            prop="paymentMethodId"
            :rules="[
              {
                required: true,
                message: this.$t('TRANSACTIONS.PAYMENT_METHOD_REQUIRED'),
                trigger: 'blur',
              },
            ]"
          >
            <base-input :label="$t('PAYMENTS.SELECT_PAYMENT_ACCOUNT')">
              <payment-method-selector
                :disabled="!newTransaction.locationId"
                :filterable="true"
                :showAll="false"
                :allowCreate="false"
                :filterLocation="newTransaction.locationId?.toString()"
                @paymentMethodChanged="paymentMethodSelected"
              />
              <input
                type="hidden"
                v-model="newTransaction.paymentMethodId"
                id="payment_method_id"
                name="payment_method_id"
              />
            </base-input>
          </el-form-item>
        </div>
      </div>
      <div
        v-if="hasRefundablePayments"
        class="col-md-8 transaction-cancelation-form"
      >
        <transaction-cancelation-form
          :sellerInvoice="transaction.sellerInvoice"
          :buyerInvoice="transaction.buyerInvoice"
          :refundSellerInvoice="false"
        />
        <el-alert
          v-if="errorMessage"
          class="mt-2"
          :title="$t('COMMON.INVALID_FORM')"
          type="error"
          :description="errorMessage"
          show-icon
        >
        </el-alert>
      </div>
    </div>

    <!-- <el-alert
      class="text-warning font-weight-bold font-size-lg"
      :closable="false"
      :title="$t('TRANSACTIONS.SELLER_PAYMENTS_WILL_BE_TRANSFERRED_TO_THIS_TRANSACTION')"
      type="warning"
      show-icon
    >
    </el-alert> -->

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        :disabled="loading"
        @click.prevent="handleSubmit"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ $t("TRANSACTIONS.CHANGE_BUYER") }}
      </base-button>
    </div>
  </el-form>
</template>

<script>
import { cloneDeep } from "lodash";
import { RadioGroup, Radio, Alert, Form, FormItem } from "element-ui";
import PaymentMethodSelector from "@/views/Pages/AdminModule/PaymentMethodManagement/components/PaymentMethodSelector.vue";
import { paymentSourcesOptions } from "@/constants/payments";
import TransactionCancelationForm from "@/components/TransactionCancelationForm.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "ChangeTransactionBuyerForm",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    PaymentMethodSelector,
    TransactionCancelationForm,
    OrganizationSelector,
    LocationSelector,
  },

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  mixins: [formMixin, requestErrorMixin],

  data() {
    return {
      loading: false,
      errorMessage: null,
      paymentSources: paymentSourcesOptions,
      transactionCancelationData: null,
      newTransaction: {
        paymentSource: null,
        price: null,
        paymentMethodId: null,
        organizationId: null,
        locationId: null,
      },
    };
  },

  created() {
    this.newTransaction.price = this.transaction.price;
  },

  computed: {
    hasRefundablePayments() {
      return this.transaction.buyerInvoice.refundable_payments.length > 0;
    },
  },
  methods: {
    async handleSubmit() {
      this.$refs["change_buyer_form"].validate(async (valid) => {
        if (valid) {
          let transaction = cloneDeep(this.transaction);
          this.transactionCancelationData = this.getCancellationData();
          if (!this.transactionCancelationData && this.errorMessage) {
            return;
          }
          this.newTransaction.transaction_cancelation_data =
            this.transactionCancelationData;
          transaction.new_transaction_data = this.newTransaction;
          try {
            this.loading = true;
            await this.$store.dispatch("transactions/changeBuyer", transaction);
            this.$notify({
              type: "success",
              message: this.$t("TRANSACTIONS.TRANSACTION_BUYER_CHANGED"),
            });
            this.$emit("transactionBuyerChanged");
          } catch (error) {
            console.error(error);
            await this.showRequestError(error);
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });
          } finally {
            this.loading = false;
          }
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("COMMON.INVALID_FORM"),
          });
        }
      });
    },
    getCancellationData() {
      /* const has_seller_refundable_payments =
        this.transaction.sellerInvoice.refundable_payments.length > 0; */
      const has_buyer_refundable_payments =
        this.transaction.buyerInvoice.refundable_payments.length > 0;

      /* let seller_pmId = null;
      let seller_source = null; */
      let buyer_pmId = null;
      let buyer_source = null;

      /* if (has_seller_refundable_payments) {
        seller_source = document.querySelector(
          'input[name="seller_payment_source"]:checked'
        )?.value;

        if (!seller_source) {
          this.errorMessage = this.$t("PAYMENTS.SELECT_SOURCE");
          return null;
        }

        if (seller_source === "DPA") {
          seller_pmId = document.getElementById(
            "seller_payment_method_id"
          ).value;
          if (!seller_pmId) {
            this.errorMessage = this.$t("PAYMENTS.SELECT_SOURCE_ACCOUNT");
            return null;
          }
        }
      } */

      if (has_buyer_refundable_payments) {
        buyer_source = document.querySelector(
          'input[name="buyer_payment_source"]:checked'
        )?.value;

        if (!buyer_source) {
          this.errorMessage = this.$t("PAYMENTS.SELECT_SOURCE");
          return null;
        }

        if (buyer_source === "DPA") {
          buyer_pmId = document.getElementById("buyer_payment_method_id").value;
          if (!buyer_pmId) {
            this.errorMessage = this.$t("PAYMENTS.SELECT_SOURCE_ACCOUNT");
            return null;
          }
        }
      }
      return {
        /* seller_source,
        seller_pmId, */
        buyer_source,
        buyer_pmId,
      };
    },
    paymentMethodSelected(paymentMethodId) {
      this.newTransaction.paymentMethodId = paymentMethodId;
    },
  },
};
</script>
