<template>
  <div class="container-fluid">
    <el-alert
      class="mb-3 text-warning font-weight-bold font-size-lg"
      :closable="false"
      :title="$t('TRANSACTIONS.TRANSACTION_HAS_PAYMENTS_TO_REFUND')"
      type="warning"
      show-icon
    >
    </el-alert>
    <el-tabs stretch>
      <el-tab-pane v-if="refundSellerInvoice && sellerInvoice.refundable_payments.length > 0">
        <span slot="label">
          <i class="fa fa-file" aria-hidden="true"></i>
          {{ $t("TRANSACTIONS.SELLER_INVOICE") }}
        </span>
        <invoice-cancelation-form
          :location-id="sellerInvoice.location_id"
          :payments="sellerInvoice.refundable_payments"
          invoice-type="seller"
        />
      </el-tab-pane>
      <el-tab-pane v-if="buyerInvoice.refundable_payments.length > 0">
        <span slot="label">
          <i class="fa fa-file" aria-hidden="true"></i>
          {{ $t("TRANSACTIONS.BUYER_INVOICE") }}
        </span>
        <invoice-cancelation-form
          :location-id="buyerInvoice.location_id"
          :payments="buyerInvoice.refundable_payments"
          invoice-type="buyer"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  RadioGroup,
  Radio,
  Tabs,
  TabPane,
  Alert,
} from "element-ui";
import InvoiceCancelationForm from "@/components/InvoiceCancelationForm.vue";
export default {
  name: "TransactionCancelationForm",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Alert.name]: Alert,
    InvoiceCancelationForm,
  },

  props: {
    sellerInvoice: {
      type: Object,
      required: false,
      default: () => null,
    },
    buyerInvoice: {
      type: Object,
      required: false,
      default: () => null,
    },
    refundSellerInvoice: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
